export const Theme = {
  colors: {
    black: '#212121',
    green: '#4fb2aa',
    yellow: '#ffa633',
    blue: '#0077c5',
    brown: '#bba686',
    red: '#c0003a',
    white: '#ffffff',
    gray: '#e3ded9',
  },
  spacing: {
    default: '2rem',
    small: '1rem',
  },
};
