import React, { Component } from 'react';
import styled from 'styled-components';
import { withFirebase } from '../Firebase/FirebaseContext';
import { navigate } from 'gatsby';
import Geocode from 'react-geocode';
import AuthUserContext from '../Session/AuthUserContext';
import moment from 'moment';

import { Button } from '../UI/Button';

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey('AIzaSyBJovmEFQX6p7sbWyMBCcx5xyrOeBIPedA');

const FormWrapper = styled.div`
  fieldset {
    border: 0;
    display: flex;
    flex-direction: column;
  }

  input {
    max-width: 400px;
  }

  button {
    width: 250px;
  }

  label {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
`;

class AddSmokeSession extends Component {
  constructor(props) {
    super(props);

    this.state = {
      geolocationBlocked: false,
      lat: null,
      lng: null,
      loading: false,
      error: false,
      strain: null,
      location: null,
      locationError: null,
      formfeedback: null,
    };
  }

  onSubmit = (authUser = null) => {
    const anonymousUser = { email: 'anonymous@whatyoublazin.com' };
    const { email } = authUser !== null ? authUser : anonymousUser;
    const { lat, lng, strain, location } = this.state;
    const createdAt = moment().format();

    this.props.firebase
      .doCreatesmokeSession(
        lat,
        lng,
        email,
        strain,
        createdAt,
        location,
      )
      .then(key => {
        // console.log('added session', key);
        localStorage.setItem(
          'whatUBlazinMapCenter',
          JSON.stringify({ lat, lng }),
        );
        this.showSuccess();
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  showSuccess = () => {
    this.setState({
      formfeedback:
        'Session has been added. Taking you back to view the map',
    });

    setTimeout(() => {
      this.setState({
        formfeedback: null,
      });
      navigate('/');
    }, 1500);
  };

  checkForGeoLocation = () => {
    this.setState({ loading: true });

    // Get latidude & longitude from address.
    Geocode.fromAddress(this.state.location).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        // console.log(lat, lng);
        this.geoSuccess(lat, lng);
      },
      error => {
        this.geoError(error);
      },
    );
  };

  geoError = error => {
    this.setState({
      loading: false,
      locationError:
        'Damn yo, we could not find your location. Try again',
    });
  };

  geoSuccess = (lat, lng) => {
    this.setState({
      lat,
      lng,
      loading: false,
      locationError: null,
    });
  };

  checkForm = () => {
    if (!this.state.lat || !this.state.lng) {
      return true;
    }

    if (this.state.strain === null || this.state.strain === '') {
      return true;
    }
  };

  strainInputChange = e => {
    this.setState({
      strain: e.target.value,
    });
  };

  locationInputChange = e => {
    this.setState({
      location: e.target.value,
    });
  };

  render() {
    return (
      <FormWrapper>
        <p>What you blazing?</p>
        <form>
          <p>{this.state.formfeedback}</p>
          <fieldset>
            <label htmlFor="strain">
              Marijuana Strain
              <input
                type="text"
                placeholder="what kind of dope you got there?"
                name="strain"
                onChange={e => {
                  this.strainInputChange(e);
                }}
              />
            </label>
            <label htmlFor="location">
              {this.state.locationError
                ? this.state.locationError
                : 'Location'}
              <input
                type="text"
                placeholder="where you at?"
                name="location"
                onChange={e => {
                  this.locationInputChange(e);
                }}
              />
              {this.state.geolocationBlocked && <input />}
              <Button
                onClick={this.checkForGeoLocation}
                text="Get Location"
                preventDefault={true}
              />
            </label>
            <div>
              {this.state.lat &&
                this.state.lng && (
                  <div>
                    Your location has been found.{' '}
                    {(this.state.strain === null ||
                      this.state.strain === '') &&
                      'We still need to know what type of trees your are blazing.'}
                  </div>
                )}
            </div>
            <AuthUserContext.Consumer>
              {authUser => (
                <Button
                  onClick={() => {
                    this.onSubmit(authUser);
                  }}
                  text={
                    this.state.loading
                      ? 'Searching for location'
                      : 'Add Smoke Session'
                  }
                  preventDefault={true}
                  disabled={this.state.loading || this.checkForm()}
                />
              )}
            </AuthUserContext.Consumer>
          </fieldset>
        </form>
      </FormWrapper>
    );
  }
}

export default withFirebase(AddSmokeSession);
