import React from 'react';
import Layout from '../components/layout';

import { AppWrapper } from '../components/styled';
import AddSmokeSession from '../components/AddSmokeSession/index';

export default () => (
  <Layout>
    <AppWrapper>
      <AddSmokeSession />
    </AppWrapper>
  </Layout>
);
