import React from 'react';
import styled from 'styled-components';
import { Theme } from '../../theme';

const StyledButton = styled.button`
  background-color: ${Theme.colors.blue};
  color: ${Theme.colors.white};
  border: none;
  font-family: 'Ubuntu', sans-serif;
`;

export const Button = ({
  preventDefault = false,
  text = 'Click Me',
  disabled = false,
  ...props
}) => {
  return (
    <StyledButton
      onClick={e => {
        if (preventDefault) e.preventDefault();
        props.onClick();
      }}
      disabled={disabled}
    >
      {text}
    </StyledButton>
  );
};
